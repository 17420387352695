import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-term-dialog',
    templateUrl: './term-dialog.component.html',
    styleUrls: ['./term-dialog.component.scss'],
})
export class TermDialogComponent implements OnInit {
    agreeTermCheck = false;
    lmpmLink = "https://lmpmhospitality.org/login"
    lmpmTermLink = "https://philippines.iom.int";
    constructor(
        private matDialogRef: MatDialogRef<TermDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            isCheck: boolean;
        },
    ) {
        this.agreeTermCheck = data.isCheck;
    }

    ngOnInit(): void {
        this.matDialogRef.backdropClick().subscribe(async () => await this.closeDialog());
    }

    async closeDialog() {
        this.matDialogRef.close(this.agreeTermCheck);
    }
    agreeTerm() {
        this.agreeTermCheck = !this.agreeTermCheck;
    }
}
