const endpoint = 'https://iom-lmpm-api.test.iomcloud.org';

export const environment = {
    production: true,
    hmr: false,
    api: {
        endpoint,
        socketServer: endpoint,
    },
    elasticAPM: {
        serviceName: 'lscm-frontend-uat',
        serverUrl: 'https://apm.dev.dgnx.io',
        distributedTracingOrigins: [endpoint],
        transactionName: 'Transaction-Name',
        sensitiveParamNames: ['password', 'currentPassword', 'newPassword', 'confirmPassword'],
    },
    enableLogging: false,
    findOutMoreUrl: 'https://lscm.uat.dgnx.io/',
    enableIomTermsAndConditions: false,
};
